import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.contact', route: { name: 'contact' } },
      ],
      listRoute: () => ({ name: 'contact' }),
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_CONTACT']),
      afterCreateRoute: result => ({
        name: 'contact-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.contact',
      defaultData: this._defaultData,
      blocks: [
        {
          title: 'form_block.base',
          data: {
            title: {
              label: 'data.title',
              disabled: true,
              type: 'text',
              maxlength: 100,
              grid: {xl: 12, lg: 12, md: 12},
              copyable: true,
            },
            name: {
              label: 'data.person_name',
              disabled: true,
              type: 'text',
              copyable: true,
            },
            email: {
              label: 'data.email',
              disabled: true,
              type: 'text',
              copyable: true,
            },
            phone: {
              label: 'data.phone',
              disabled: true,
              type: 'text',
              copyable: true,
            },
            code: {
              label: 'contact.data.code',
              disabled: true,
              type: 'text',
              copyable: true,
            },
            type: {
              label: 'contact.data.type',
              type: 'selection',
              // @ts-ignore
              options: () => this.vm.$contactType.map(type => ({
                text: this.vm?.$t(`contact.data.type.${type}`),
                value: type,
              })),
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            status: {
              label: 'data.status',
              type: 'selection',
              // @ts-ignore
              options: () => this.vm.$contactStatus.map(status => ({
                text: this.vm?.$t(`contact.data.status.${status}`),
                value: status,
              })),
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            note: {
              label: 'contact.data.note',
              type: 'textarea',
              grid: {xl: 12, lg: 12, md: 12},
              maxlength: 500,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },

        {
          title: 'contact.form_block.comments',
          data: {
            comments: {
              grid: {xl: 12, lg: 12, md: 12},
              component: () => import('./contactForm/comments.vue'),
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
              readonly: true,
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
          },
        },
      ],
    }
  }
}

export default new formConfig()
